import React, { useState } from "react"
import {
  Brain,
  Cog,
  Database,
  FileText,
  Eye,
  Code,
  Wrench,
  Shield,
  HeartPulse,
  Leaf,
} from "lucide-react"
import "./AISliderSection.css"

export default function AISliderSection() {
  const sidebarItems = [
    {
      id: "01",
      title: "Generative AI",
      icon: Brain,
      description: [
        "Our AI experts leverage cutting-edge generative AI technologies like GANs, GPT, and DALL-E to create novel content in images, music, text, and code, enhancing human creativity and innovation.",
        "The development process involves data training, model building, and content generation to produce high-quality, AI-driven applications.",
      ],
      applications: [
        "Content Generation",
        "API Development",
        "Music Composition",
        "Automated code generation",
      ],
    },
    {
      id: "02",
      title: "Machine Learning",
      icon: Brain,
      description: [
        "Our AI experts develop advanced machine learning and predictive analytics models using tools like Python, R, TensorFlow, and Scikit-learn to analyze data and forecast trends, empowering data-driven decision-making.",
        "The process includes data collection, feature engineering, model training, and validation to ensure accurate, actionable insights for strategic decisions.",
      ],
      applications: [
        "Sales Trend Forecasting",
        "Price Prediction",
        "Recommendation Systems",
        "Energy Consumption Forecasting",
      ],
    },
    {
      id: "03",
      title: "Deep Learning",
      icon: Database,
      description: [
        "Our AI experts leverage deep learning, using multi-layer neural networks with tools like TensorFlow, PyTorch, and Keras to tackle complex problems and learn from vast data sets.",
        "The process involves data preprocessing, model architecture design, training, and fine-tuning to ensure high accuracy and performance in solutions.",
      ],
      applications: [
        "Algorithmic Trading",
        "Anomaly Detection",
        "Adaptive Learning System",
        "Adaptive Learning System",
      ],
    },
    {
      id: "04",
      title: "NLP",
      icon: FileText,
      description: [
        "Our NLP experts utilize techniques such as sentiment analysis, named entity recognition, and text generation with tools like NLTK, SpaCy, and BERT to enable machines to understand and generate human language.",
        "The NLP process includes text preprocessing, model building, and fine-tuning to deliver highly accurate language-based AI solutions",
      ],
      applications: [
        "Customer Service Chatbots",
        "Anomaly Detection",
        "Adaptive Learning System",
        "Industrial Equipment Monitoring",
      ],
    },
    {
      id: "05",
      title: "Computer Vision",
      icon: Eye,
      description: [
        "Our AI experts use tools like OpenCV, TensorFlow, and PyTorch to build sophisticated image and video analysis systems, enabling precise visual recognition capabilities.",
        "The computer vision process includes data annotation, model training, and deployment, empowering machines to interpret and understand digital images and videos effectively.",
      ],
      applications: [
        "Drone Object Detection",
        "Medical Imaging and Diagnostics",
        "Virtual Try-On Solutions",
        "Smart Retail",
      ],
    },
    {
      id: "06",
      title: "OCR and HWR",
      icon: Code,
      description: [
        "Our experts leverage tools like Tesseract, ABBYY FineReader, TensorFlow, and PyTorch for accurate OCR and handwriting recognition, converting printed and handwritten text into machine-encoded formats.",
        "The process involves image preprocessing, text detection, and recognition to ensure high accuracy in digitizing both printed and handwritten content.",
      ],
      applications: [
        "Check Scanning",
        "Historical Document Preservation",
        "Invoice and Receipt Processing",
        "Legal Document Management",
      ],
    },
    {
      id: "07",
      title: "Custom Model",
      icon: Wrench,
      description: [
        "Our AI experts create unique, business-specific AI models using frameworks like TensorFlow, PyTorch, and Keras, ensuring precise and effective solutions.",
        "The process includes data collection, model architecture design, iterative training, and validation to achieve optimal performance and results.",
      ],
      applications: [
        "Banking Risk Assessment",
        "Specialized Fraud Detection Systems",
        "Bespoke Demand Forecasting Models",
        "E-commerce Product Recommendations",
      ],
    },
    {
      id: "08",
      title: "RAG",
      icon: Shield,
      description: [
        "Our experts utilize frameworks like LangChain, Hugging Face, PyTorch, and OpenAI APIs to implement cutting-edge RAG systems, combining retrieval mechanisms with generative AI for contextually relevant outputs.",
        "The process integrates a knowledge base with language models, ensuring precise data retrieval and enhanced generative responses tailored to user queries.",
      ],
      applications: [
        "Knowledge Base Assistants",
        "Personalized Customer Support",
        "Research Summarization",
        "Compliance Queries",
      ],
    },
    {
      id: "09",
      title: "Semantic Search",
      icon: HeartPulse,
      description: [
        "Our experts leverage state-of-the-art technologies like Elasticsearch, OpenAI Embeddings, Hugging Face Transformers, and FAISS to build robust semantic search solutions that understand intent and context beyond keywords.",
        "The process involves embedding creation, vector indexing, and similarity scoring to deliver highly relevant search results aligned with user queries.",
      ],
      applications: [
        "Knowledge Management",
        "Content Recommendations",
        "Legal Document Retrieval",
        "Academic Research Portals",
      ],
    },
    {
      id: "10",
      title: "AI Chatbots",
      icon: Leaf,
      description: [
        "Our experts utilize frameworks like Dialogflow, Rasa, IBM Watson, and OpenAI's GPT to design intelligent conversational agents capable of understanding and responding to user inputs with natural language.",
        "The process involves intent recognition, contextual understanding, and dynamic response generation for seamless interactions.",
      ],
      applications: [
        "Patient Query Management",
        "Financial Advisory Chatbots",
        "Travel Booking Assistants",
        "Interactive Learning Tutors",
      ],
    },
  ]

  const [activeItem, setActiveItem] = useState(sidebarItems[0])

  const ContentSection = ({ item }) => (
    <section className="space-y-6 p-6 rounded-lg shadow-md lg:shadow-none  lg:h-full lg:flex lg:flex-col lg:justify-center">
      <div className="flex items-center gap-2 md:gap-4 lg:gap-8 flex-nowrap">
        <div className="flex-shrink-0 flex items-center justify-center w-12 h-12 md:w-14 md:h-14 lg:w-28 lg:h-28 rounded-xl md:rounded-2xl lg:rounded-3xl bg-gradient-to-r from-blue-600 to-blue-800">
          <item.icon
            className="w-10 h-10 md:w-12 md:h-12 lg:w-20 lg:h-20 text-white p-2 rounded-lg"
            aria-hidden="true"
          />
        </div>

        <div className="lg:text-5xl md:text-3xl text-xl font-bold text-shark-500 break-words">
          {item.title}
        </div>
      </div>

      <div className="space-y-4 text-shark-500 text-sm lg:text-lg font-semibold">
        {item?.description.map((line, index) => (
          <div key={index} className="flex items-start">
            <span className="mr-2">&#x2022;</span>
            <p className="flex-1">{line}</p>
          </div>
        ))}
      </div>

      <div className="space-y-4">
        {/* <h3 className="text-xl md:text-2xl lg:text-4xl font-bold text-shark-500">
          Versatile Applications
        </h3> */}
        <ul className="flex flex-wrap gap-2 ">
          {item.applications.map(app => (
            <li
              key={app}
              className="px-3 py-1 text-shark-500 rounded-full text-sm lg:text-lg border border-blue-600 font-semibold"
            >
              {app}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )

  return (
    <div className="flex flex-col lg:flex-row px-4 sm:px-6 w-full lg:px-12 bg-white py-4 sm:py-6 lg:py-12 aislider-height">
      {/* Sidebar - visible only on large screens */}
      <div
        className="lg:flex flex-col h-full w-full lg:w-1/2 p-4 lg:p-16 overflow-y-auto custom-rounded custom-rounded-lg justify-between custom-scrollbar"
        style={{
          background: "linear-gradient(230deg, #335FFF 42.88%, #000 105.66%)",
        }}
      >
        <nav
          className="custom-scrollbar h-full overflow-y-auto pr-4 w-full"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "white #ffffff40",
          }}
        >
          <ul className="">
            {sidebarItems.map(item => (
              <li
                key={item.id}
                className={
                  item.id === activeItem.id
                    ? "text-white"
                    : "text-white opacity-70"
                }
              >
                <button
                  onClick={() => setActiveItem(item)}
                  className="w-full text-left py-2 px-4"
                >
                  {/* ID with no border */}
                  <span className="font-bold text-xl lg:text-3xl pb-4 border-none">
                    {item.id}.
                  </span>
                  {/* Title with border-bottom */}
                  <span className="ml-2 font-bold text-xl lg:text-3xl pb-4 border-b border-white/20 block w-full">
                    {item.title}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex-grow"></div>{" "}
        {/* This spacer helps ensure even margin distribution */}
      </div>
      {/* Main Content */}
      <div
        className=" h-full w-full lg:w-1/2 p-0 lg:p-6 overflow-y-auto  lg:border-2 border-[rgba(44,35,232,0.2)]
        custom-ai-rounded custom-ai-rounded-lg  space-y-6 flex flex-col justify-between"
      >
        {/* For large screens, show only active item */}
        <div className="lg:block h-full">
          <ContentSection item={activeItem} />
        </div>
        {/* For small screens, show all items */}
        {/* <div className="lg:hidden space-y-6">
          {sidebarItems.map(item => (
            <ContentSection key={item.id} item={item} />
          ))}
        </div> */}
      </div>
    </div>
  )
}
